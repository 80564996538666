import logo from './logo.svg';
import './App.css';
import axios from 'axios';
import { Input, Button, Typography } from 'antd';
import { useState } from 'react';

const { Title, Paragraph, Text, Link } = Typography;
const { TextArea } = Input;

function App() {
  const [text, setText] = useState('');
  const [result, setResult] = useState('');

  const onSubmit = () => {
    if (!text) return;
    axios({
      method: 'post',
      url: 'https://demo.computervision.com.vn/api/v2/normalizer/vietnamese',
      data: { text },
    })
      .then((res) => setResult(res.data.result))
      .catch((err) => console.log(err));
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <div className='App'>
      <div style={{ width: '100%', maxWidth: 700, margin: 'auto' }}>
        <Title>Thư viện chuẩn hóa văn bản Tiếng Việt</Title>
        <TextArea
          rows={6}
          style={{ fontSize: 16 }}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={onKeyDown}
        />
        <Button
          type='primary'
          size='large'
          style={{ marginTop: 20, background: '#EC1C2A', borderColor: '#EC1C2A' }}
          onClick={onSubmit}
        >
          Chuẩn hóa
        </Button>
        {result && (
          <>
            <div style={{ fontSize: 18, fontWeight: 600, textAlign: 'left', margin: '20px 0' }}>Kết quả:</div>
            <TextArea rows={6} style={{ fontSize: 16 }} value={result} readOnly />
          </>
        )}
      </div>
    </div>
  );
}

export default App;
